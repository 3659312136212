import React from "react";

const Input = ({
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  maximLength,
  minimLength,
  watch,
  isEmail,
  isPassword,
  isNumerical,
  matchField,
}) => {
  return (
    <div className="field-name w-100">
      <input
        type={isPassword ? "password" : "text"}
        className="form-control-ac border-secondary border-end rounded-pill"
        placeholder={placeHolder}
        {...register(fieldName, {
          required: {
            value: isRequired,
            message: `Este campo es requerido *.`,
          },

          validate: (val) => {
            if (matchField) {
              if (watch("Password") !== val) {
                return "Los campos 'Contraseña' y 'Confirmar Contraseña' no coinciden.";
              }
            }
          },

          pattern: {
            value: isEmail
              ? /^\S+@\S+$/i
              : isPassword
              ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
              : isNumerical
              ? /^[0-9]+$/
              : "",
            message: isEmail
              ? "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve)."
              : isPassword
              ? "La contraseña debe tener al menos 1 número, 1 carácter especial, 1 carácter en MAYÚSCULAS y 1 carácter en minúscula."
              : isNumerical
              ? "Este campo solo permite caracteres numéricos [0-9]"
              : "",
          },

          maxLength: {
            value: maximLength,
            message: `Este campo debe tener como máximo ${maximLength} caracteres.`,
          },
          minLength: {
            value: minimLength,
            message: `Este campo debe tener como mínimo ${minimLength} caracteres.`,
          },
        })}
      />

      <p className="lg-error">
        {
          //If error exists in the textField
          errors[fieldName] && errors[fieldName].message
        }
      </p>
    </div>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import TextArea from "../../components/shared/TextArea/TextArea";
import "./Aliados.css";
import ReactModal from "react-modal";
import checkicon from "../../assets/svg/icon-success.svg";
import erroricon from "../../assets/svg/error-icon.svg";
import axios from "axios";
import Cookies from "universal-cookie";

const AgregarAliado = ({ open, close }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      func: "",
      Prefijo: "",
    },
  });

  const [openM, setOpen] = useState();

  const onSubmit = (data) => {
    setIsLoading(true);

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    data["User_Generador"] = base64ToStringNew;
    data["func"] = JSON.parse(data.func);
    data["CodigoConvenio"] = data.func.cod;
    data["NombreConvenio"] = data.func.nombre;
    data["Instagram"] = data["Instagram"].replace(/\s/g, "");
    data["Usuario"] = data["Usuario"].replace(/\s/g, "");

    if (
      data["CodigoConvenio"] !== undefined &&
      data["NombreConvenio"] !== undefined
    ) {
      delete data["func"];
    }

    axios
      .post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_AGREGAR_AC}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        setreqstatus(response.status);
        setcredenciales(response.data);

        if (response.status === 400) {
          setshowElement(true);
          setTimeout(() => {
            setshowElement(false);
          }, 2500);
        } else {
          reset();
        }
      })
      .catch(function (error) {
        setshowElement(true);
        setIsLoading(false);
        setreqstatus(400);
        setTimeout(() => {
          setshowElement(false);
        }, 2500);

        console.error("Error:", error);
      });
  };

  const cookies = new Cookies();
  const [modalidad, setModalidad] = useState();
  const [sucursales, setSucursales] = useState();
  const [reqstatus, setreqstatus] = useState(0);
  const [credenciales, setcredenciales] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showElement, setshowElement] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  useEffect(() => {
    setOpen(open);
    const getData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_AC_CONVENIO}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          var convenio_l = res.data;
          var list = [];

          convenio_l.forEach((obj) => {
            // get select option
            list.push({
              label: obj.nombre_convenio,
              value: { cod: obj.codigo_convenio, nombre: obj.nombre_convenio },
            });
          });

          setModalidad(list);
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_SUCURSALES}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          var sucursales_l = res.data;
          var lista_sucursales = [];

          sucursales_l.forEach((obj) => {
            // get select option
            lista_sucursales.push({
              label: obj.Descripcion,
              value: obj.Prefijo,
            });
          });

          setSucursales(lista_sucursales);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getData();
  }, [credenciales, open, tkn]);

  function close_Cred() {
    setOpen(!open);
    window.location.reload();
  }

  return (
    <ReactModal
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered w-100"
      tabIndex="-1"
      shouldCloseOnOverlayClick={true}
      onRequestClose={close}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal mt-1 me-1"
              onClick={
                credenciales !== null && reqstatus === 200 ? close_Cred : close
              }
            ></button>
          </div>

          <div className="modal-body mbdy">
            {reqstatus === 0 || reqstatus === 400 ? (
              <form>
                <div className="mb-4">
                  <h2 className="aliados-add">Agregar Aliado Comercial</h2>
                </div>

                {reqstatus === 400 && showElement === true && (
                  <div className="w-100 d-flex flex-column mb-4 error-ac">
                    <img
                      src={erroricon}
                      alt="ícono error"
                      className="img-fluid mx-auto"
                      width={25}
                      height={20}
                    />
                    <p className="lg-error ps-0 mx-auto">{credenciales}</p>
                  </div>
                )}

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="NombreAC"
                      register={register}
                      errors={errors}
                      placeHolder="Nombre del Aliado Comercial"
                      isRequired={true}
                      maximLength={200}
                      minimLength={2}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4">
                    <Input
                      fieldName="Representante"
                      register={register}
                      errors={errors}
                      placeHolder=" Representante"
                      isRequired={true}
                      maximLength={50}
                      minimLength={2}
                    />
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="DocumentoIdentidad"
                      register={register}
                      errors={errors}
                      placeHolder="Cédula / Rif"
                      isRequired={true}
                      maximLength={10}
                      minimLength={2}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4">
                    <Input
                      fieldName="Supervisor"
                      register={register}
                      errors={errors}
                      placeHolder="Supervisor"
                      isRequired={true}
                      maximLength={50}
                      minimLength={2}
                    />
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="Email"
                      register={register}
                      errors={errors}
                      placeHolder="Email"
                      isRequired={true}
                      isEmail={true}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4 d-flex flex-column">
                    <select
                      className="form-select  agr-select rounded-pill border-secondary"
                      name="func"
                      {...register("func", {
                        required: {
                          value: true,
                          message:
                            "El campo Modalidad / Tipo de Contrato es requerido *.",
                        },
                      })}
                    >
                      <option value="" disabled>
                        Modalidad / Tipo de Contrato
                      </option>

                      {modalidad &&
                        modalidad.map((comment) => (
                          <option
                            key={comment.value.cod}
                            value={JSON.stringify(comment.value)}
                          >
                            {comment.label}
                          </option>
                        ))}
                    </select>
                    {errors.func && (
                      <p className="lg-error"> {errors.func.message}</p>
                    )}
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="Telefono"
                      register={register}
                      errors={errors}
                      placeHolder="Teléfono"
                      isRequired={true}
                      maximLength={14}
                      minimLength={2}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4 d-flex flex-column">
                    <select
                      className="form-select agr-select rounded-pill border-secondary"
                      name="Prefijo"
                      {...register("Prefijo", {
                        required: {
                          value: true,
                          message: "El campo Sucursal es requerido *.",
                        },
                      })}
                    >
                      <option value="" disabled>
                        Sucursal
                      </option>

                      {sucursales &&
                        sucursales.map((sucursal) => (
                          <option key={sucursal.value} value={sucursal.value}>
                            {sucursal.label}
                          </option>
                        ))}
                    </select>
                    {errors.Prefijo && (
                      <p className="lg-error"> {errors.Prefijo.message}</p>
                    )}
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="Instagram"
                      register={register}
                      errors={errors}
                      placeHolder="Instagram"
                      isRequired={false}
                      maximLength={30}
                      minimLength={3}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4">
                    <Input
                      fieldName="Usuario"
                      register={register}
                      errors={errors}
                      placeHolder="Nick"
                      isRequired={true}
                      maximLength={8}
                      minimLength={3}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <p className="mx-auto mb-0 agr-ubicacion">Ubicación</p>
                  <hr className="separator" />

                  <div className="d-flex aliados-wrp w-100">
                    <div className="mb-3 w-100 ac-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Dirección
                      </label>
                      <TextArea
                        fieldName="Direccion"
                        register={register}
                        errors={errors}
                        placeHolder="Av. 4 Bella Vista entre calles 68 y 69. Maracaibo, Edo. Zulia."
                        isRequired={true}
                        maximLength={150}
                        minimLength={5}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex w-100">
                  <div className="mx-auto">
                    <Button
                      text={"Guardar Datos"}
                      myClass={"btn btn-save pe-4 ps-4"}
                      funct={handleSubmit(onSubmit)}
                      loading={isLoading}
                      loadingMsg={"Guardando Datos..."}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <div className="d-flex">
                  {reqstatus === 200 ? (
                    <div className="w-100">
                      <img
                        src={checkicon}
                        alt="ícono check"
                        className="img-fluid"
                      />
                      <p className="ac-guardado">
                        ¡Los datos del Aliado Comercial se han guardado
                        exitosamente!
                      </p>

                      {credenciales &&
                        Object.entries(credenciales).map(([key, val], i) => (
                          <div className="d-flex flex-column" key={i}>
                            <label>
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </label>
                            <p className="modal-text-1">{val}</p>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="w-100">
                        <img
                          src={erroricon}
                          alt="ícono error"
                          className="img-fluid"
                        />
                        <p className="modal-error">{credenciales}</p>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default AgregarAliado;

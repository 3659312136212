import React from "react";
import "./Card.css";

const Card = ({
  title,
  daily,
  month,
  funct,
  myClass,
  m_fecha,
  mes_A,
  planClass,
}) => {
  const current = new Date();
  const month_n = current.toLocaleString("default", { month: "long" });

  return (
    <div className={myClass} onClick={funct}>
      <div className="card-body">
        <h5 className={`card-title ${planClass}`}>{title}</h5>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <p className="card-text me-3">{daily}</p>
            </div>

            <p className="card-text">
              Diarias{" "}
              {m_fecha && (
                <span>
                  {" "}
                  :{" "}
                  {`${current.getDate()}/${
                    current.getMonth() + 1
                  }/${current.getFullYear()}`}
                </span>
              )}
            </p>
          </div>

          <div className="d-flex">
            <div className="d-flex align-items-center">
              <p className="card-text me-3">{month}</p>
            </div>

            {mes_A ? (
              <div className="d-flex align-items-center">
                <p className="card-text mb-0 me-1">Mes:</p>
                <span className="card-text">
                  {month_n.charAt(0).toUpperCase() + month_n.slice(1)}
                </span>
              </div>
            ) : (
              <>
                <p className="card-text mb-0 me-1">Total</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import Modal1 from "../../components/Modal/Modal";
import TextArea from "../../components/shared/TextArea/TextArea";
import "./Usuarios.css";

const EditarUser = () => {
  const {
    register,
    handleSubmit,
    reset: reset2,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    reset,
    handleSubmit: handleSubmit2,
  } = useForm({
    defaultValues: {
      Roles: "",
      Codigo_ac: "",
    },
  });

  const [roles, setRoles] = useState();
  const [aliados, setAliados] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setuser] = useState();
  const [modal, setModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [modalText, setmodalText] = useState("");
  const [Icon, setIcon] = useState("");
  const [MyClass, setClass] = useState("");

  // modal
  const Open = () => {
    setModal(!modal);
  };

  const onSubmit = (data) => {
        setIsLoading(true);
    fetch(`${process.env.REACT_APP_URL}get-user/${data.Correo}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.length !== undefined) {
          setuser(json);
          setIsLoading(false);

          const filter = (obj, search) =>
            obj.filter((o) =>
              Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(search.toLowerCase())
              )
            );

          var rol = filter(roles, json[0].Roles);

          var aliado;

          if (aliados !== undefined) {
            aliado = filter(aliados, json[0].AliadoComercial);
          }

          reset({
            Nombre: json[0].Nombre,
            Apellido: json[0].Apellido,
            Correo: json[0].Correo,
            Telefono: json[0].Telefono,
            Comentario: json[0].Comentario,
            Direccion: json[0].Direccion,
            Roles: rol.length > 0 ? rol[0].value : "",
            Codigo_ac: aliado.length > 0 ? aliado[0].value : "",
          });

          reset2({
            Correo: "",
          });
        } else {
          setIsLoading(false);
          Open();
          setIcon("fa-solid fa-circle-xmark fa-lg modal-icon-error icon-m");
          setClass("modal-icon-error");
          setmodalTitle("Por favor verifique los datos Correo incorrecto");
          setmodalText("");
        }
      });
  };

  const onSubmitCh = (info) => {
    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    info["Usuario_modificacion"] = base64ToStringNew;

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_URL}editar-user/${user[0].Id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usuario: info,
      }),
    })
      .then((json) => {
        setIsLoading(false);
        if (json.status === 204) {
          setTimeout(() => {
            reset({
              Nombre: "",
              Apellido: "",
              Correo: "",
              Telefono: "",
              Comentario: "",
              Direccion: "",
              Roles: "",
              Codigo_ac: "",
            });
            setuser(undefined);
          }, 2000);
          Open();

          setIcon("fa-solid fa-circle-check fa-lg modal-icon-success icon-m");
          setClass("modal-icon-success");
          setmodalTitle("¡Datos Actualizados!");
          setmodalText("");
        } else {
          Open();
          setIcon("fa-solid fa-circle-xmark fa-lg modal-icon-error icon-m");
          setClass("modal-icon-error");
          setmodalTitle("Se produjo un error. Vuelve a intentarlo más tarde.");
          setmodalText("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const getData = () => {
      fetch(`${process.env.REACT_APP_URL}roles`)
        .then((response) => response.json())
        .then((json) => {
          var list = [];

          json.forEach((obj) => {
            // get select option
            list.push({
              label: obj.descripcion,
              value: obj.codigo,
            });
          });

          setRoles(list);
        });

      fetch(`${process.env.REACT_APP_URL}aliados`)
        .then((response) => response.json())
        .then((json) => {
          var lista_sucursales = [];

          json.forEach((obj) => {
            // get select option
            lista_sucursales.push({
              label: obj.descripcion,
              value: obj.codigo_aliado,
            });
          });

          setAliados(lista_sucursales);
        });
    };

    getData();
  }, []);

  return (
    <div className=" mx-auto">
      <div className="add-user">
        <div className="modal-header mhdr"></div>
        <div className="modal-body mbdy">
          {user === undefined ? (
            <div>
              <p className="text-center">
                Por favor, ingresa el nick del usuario a editar.
              </p>

              <Input
                fieldName="Correo"
                register={register}
                errors={errors}
                placeHolder="Correo"
                isRequired={true}
                isEmail={true}
                maximLength={55}
                minimLength={2}
              />

              <Button
                text={"Continuar"}
                myClass={"btn btn-save pe-4 ps-4 w-100 mt-3"}
                funct={handleSubmit(onSubmit)}
                loading={isLoading}
                loadingMsg={"Validando datos"}
              />
            </div>
          ) : (
            <div>
              <form>
                {/* <div className="mb-4">
                  <h2 className="aliados-add">Editar Usuario</h2>
                </div> */}

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="Nombre"
                      register={register2}
                      errors={errors2}
                      placeHolder="Nombre"
                      isRequired={true}
                      maximLength={50}
                      minimLength={2}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4">
                    <Input
                      fieldName="Apellido"
                      register={register2}
                      errors={errors2}
                      placeHolder=" Apellido"
                      isRequired={true}
                      maximLength={50}
                      minimLength={2}
                    />
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <Input
                      fieldName="Telefono"
                      register={register2}
                      errors={errors2}
                      placeHolder="Teléfono"
                      isRequired={false}
                      isNumerical={true}
                      maximLength={11}
                      minimLength={10}
                    />
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4">
                    <Input
                      fieldName="Correo"
                      register={register2}
                      errors={errors2}
                      placeHolder="Correo"
                      isRequired={true}
                      isEmail={true}
                      maximLength={55}
                      minimLength={2}
                    />
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-50 ac-group">
                    <select
                      className="form-select agr-select rounded-pill border-secondary"
                      name="Roles"
                      {...register2("Roles", {
                        required: {
                          value: true,
                          message: "El campo Roles es requerido *.",
                        },
                      })}
                    >
                      <option value="" disabled>
                        Roles
                      </option>

                      {roles &&
                        roles.map((comment) => (
                          <option key={comment.value} value={comment.value}>
                            {comment.label}
                          </option>
                        ))}
                    </select>
                    {errors2.Roles && (
                      <p className="lg-error"> {errors2.Roles.message}</p>
                    )}
                  </div>

                  <div className="mb-3 w-50 ac-group ms-4 d-flex flex-column">
                    <select
                      className="form-select agr-select rounded-pill border-secondary"
                      name="Codigo_ac"
                      {...register2("Codigo_ac", {
                        required: {
                          value: true,
                          message: "El campo Aliado Comercial es requerido *.",
                        },
                      })}
                    >
                      <option value="" disabled>
                        Aliado Comercial
                      </option>

                      {aliados &&
                        aliados.map((comment) => (
                          <option key={comment.value} value={comment.value}>
                            {comment.label}
                          </option>
                        ))}
                    </select>
                    {errors2.Codigo_ac && (
                      <p className="lg-error">{errors2.Codigo_ac.message}</p>
                    )}
                  </div>
                </div>

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-100 ac-group">
                    <Input
                      fieldName="Comentario"
                      register={register2}
                      errors={errors2}
                      placeHolder="Comentario"
                      isRequired={false}
                      maximLength={100}
                      minimLength={2}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <p className="mx-auto mb-0 agr-ubicacion">Ubicación</p>
                  <hr className="separator" />

                  <div className="d-flex aliados-wrp w-100">
                    <div className="mb-3 w-100 ac-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Dirección
                      </label>
                      <TextArea
                        fieldName="Direccion"
                        register={register2}
                        errors={errors2}
                        placeHolder="Av. 4 Bella Vista entre calles 68 y 69. Maracaibo, Edo. Zulia."
                        isRequired={false}
                        maximLength={150}
                        minimLength={5}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex w-100">
                  <div className="mx-auto">
                    <Button
                      text={"Actualizar Datos"}
                      myClass={"btn btn-save pe-4 ps-4"}
                      funct={handleSubmit2(onSubmitCh)}
                      loading={isLoading}
                      loadingMsg={"Actualizando Datos..."}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      <Modal1
        open={modal}
        close={() => Open()}
        modalType={3}
        modalTitle={modalTitle}
        modalText={modalText}
        icon={Icon}
        title_class={MyClass}
      />
    </div>
  );
};

export default EditarUser;

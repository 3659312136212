export async function formatMDT(arr) {
  var infoArrayMDT = arr;

  infoArrayMDT.records.forEach((obj) => {
    if (!obj.Estado || obj.Estado === "") {
      obj.Estado = "SIN ESTATUS";
    } else {
      if (obj.Estado === "EnOperaciN" || obj.Estado === "operacion") {
        obj.Estado = "EN OPERACION";
      } else if (obj.Estado === "DiseOCerrado" || obj.Estado === "cerrado") {
        obj.Estado = "DISEÑO CERRADO";
      } else if (obj.Estado === "EnDiseO") {
        obj.Estado = "EN DISEÑO";
      } else if (
        obj.Estado === "ExploraciNComercial" ||
        obj.Estado === "exploracion"
      ) {
        obj.Estado = "EXPLORACION COMERCIAL";
      } else if (
        obj.Estado === "EnConstrucciN" ||
        obj.Estado === "construccion"
      ) {
        obj.Estado = "EN CONSTRUCCION";
      } else {
        obj.Estado = obj.Estado.toUpperCase();
      }
    }

    if (!obj.Ac || obj.Ac === "") {
      obj.Ac = "ZONA AIRTEK";
    }

    if (obj.Ac === "Zona Airtek") {
      obj.Ac = obj.Ac.toUpperCase();
    }

    if (!obj.Reasignado_Ac || obj.Reasignado_Ac === "") {
      obj.Reasignado_Ac = "SIN REASIGNAR";
    }

    if (!obj.Nodo || obj.Nodo === "") {
      obj.Nodo = "OLT SIN ASIGNAR";
    }

    if (!obj.UserReasignacion || obj.UserReasignacion === "") {
      obj.UserReasignacion = "SIN USER";
    }
  });

  return infoArrayMDT;
}

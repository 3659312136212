import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import TextArea from "../../components/shared/TextArea/TextArea";
import "./Aliados.css";
import checkicon from "../../assets/svg/icon-success.svg";
import erroricon from "../../assets/svg/error-icon.svg";
import axios from "axios";
import Cookies from "universal-cookie";
import { getAliado } from "../../utils/functions/getAliado";

const ModificarAliado = ({ cod }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const cookies = new Cookies();
  const [aliado, setAliado] = useState();
  const [user, setUser] = useState();
  const [req_status, setreq_status] = useState();
  const [sent, setsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  const id_aliado = cod;

  const onSubmit = (data) => {
    data["User_Modificacion"] = user;

    // remove empty elements
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== "")
    );

    axios
      .patch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_EDITAR_AC}${id_aliado}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        }
      )

      .then(function (response) {
        setIsLoading(false);
        setreq_status(response.status);
        if (response.status === 204) {
          setsent(true);
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
        if (response.status === 400) {
          setsent(false);
          setreq_status(response.status);
        }
        if (response.status === 401) {
          setsent(false);
          setreq_status(response.status);
        }
      })
      .catch(function (error) {
        setsent(false);
        setreq_status(403)
        console.error("Error:", error);
      });

    reset();
  };

  useEffect(() => {
    const getData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ALIADOS_TODOS}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          var aliados_actv = await getAliado(res.data, id_aliado);
          setAliado(...aliados_actv);

          reset({
            RazonSocial: aliados_actv[0].descripcion,
            Email: aliados_actv[0].email,
            Instagram: aliados_actv[0].instagram,
            Representante: aliados_actv[0].representante,
            Direccion: aliados_actv[0].direccion,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getData();

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");
    setUser(base64ToStringNew);
  }, [id_aliado, reset, tkn]);

  return (
    <div>
      {sent === false && req_status === undefined ? (
        <form>
          <p className="mb-3 aliados-add">
            {aliado !== undefined ? aliado.descripcion : "Aliado Comercial"}
          </p>

          <div className="d-flex aliados-wrp w-100">
            <div className="mb-3 w-50 ac-group">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Nombre
              </label>
              <Input
                fieldName="RazonSocial"
                register={register}
                errors={errors}
                placeHolder={
                  aliado !== undefined
                    ? aliado.descripcion
                    : "Corporación Matrix TV C.A"
                }
                isRequired={true}
                maximLength={200}
                minimLength={2}
              />
            </div>

            <div className="mb-3 w-50 ac-group ms-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <Input
                fieldName="Email"
                register={register}
                errors={errors}
                placeHolder={
                  aliado !== undefined
                    ? aliado.email
                    : "Corporación Matrix TV C.A"
                }
                isRequired={true}
                isEmail={true}
              />
            </div>
          </div>

          <div className="d-flex aliados-wrp w-100">
            <div className="mb-3 w-50 ac-group">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Instagram
              </label>
              <Input
                fieldName="Instagram"
                register={register}
                errors={errors}
                placeHolder={
                  aliado !== undefined ? aliado.instagram : "airtekinternet"
                }
                isRequired={false}
                maximLength={30}
                minimLength={3}
              />
            </div>

            <div className="mb-3 w-50 ac-group ms-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Representante
              </label>

              <Input
                fieldName="Representante"
                register={register}
                errors={errors}
                placeHolder={
                  aliado !== undefined ? aliado.representante : "John Doe"
                }
                isRequired={true}
                maximLength={50}
                minimLength={2}
              />
            </div>
          </div>

          <div className="d-flex aliados-wrp w-100">
            <div className="mb-3 w-50 ac-group">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Supervisor
              </label>
              <Input
                fieldName="Supervisor"
                register={register}
                errors={errors}
                placeHolder="Jane Doe"
                isRequired={false}
                maximLength={50}
                minimLength={2}
              />
            </div>
          </div>

          <div className="d-flex aliados-wrp w-100">
            <div className="mb-3 w-100 ac-group">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Dirección
              </label>
              <TextArea
                fieldName="Direccion"
                register={register}
                errors={errors}
                placeHolder={
                  aliado !== undefined
                    ? aliado.direccion
                    : "Av. 4 Bella Vista entre calles 68 y 69. Maracaibo, Edo. Zulia."
                }
                isRequired={true}
                maximLength={150}
                minimLength={5}
              />
            </div>
          </div>

          <div className="d-flex w-100">
            <div className="mx-auto">
              <Button
                text={"Guardar"}
                myClass={"btn btn-save pe-5 ps-5"}
                funct={handleSubmit(onSubmit)}
                loading={isLoading}
                loadingMsg={"Actualizando Datos..."}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <div className="d-flex">
            {req_status === 204 ? (
              <div className="w-100">
                <img
                  src={checkicon}
                  alt="ícono check"
                  className="img-fluid mb-3"
                />
                <p className="fw-bold">
                  ¡Los datos del Aliado Comercial se han actualizado
                  exitosamente!
                </p>
              </div>
            ) : (
              <React.Fragment>
                <div className="w-100">
                  <img
                    src={erroricon}
                    alt="icono error"
                    className="img-fluid"
                  />
                  <p className="fw-bold modal-error">
                    Se produjo un error. Vuelve a intentarlo más tarde.
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModificarAliado;

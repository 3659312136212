import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

const Loader = () => {
  return (
    <div className="w-100 h-100 d-flex pt-5">
      <div className="mx-auto h-100">
        <BeatLoader color={"#0066ff"} size={19} />
      </div>
    </div>
  );
};

export default Loader;

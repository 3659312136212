import React from "react";
import "./Navbar.css";

const NavbarAlerts = ({ icon, notif, funct }) => {
  return (
    <div className="circle position-relative me-2">
      <div className=" mx-auto my-auto">
        <i className={`nb-icon ${icon}`} onClick={funct}></i>
      </div>

      {notif ? (
        <span className="position-absolute bottom-0 start-100 bg-danger border border-light rounded-circle span-ntf">
          <span className="visually-hidden">New alerts</span>
        </span>
      ) : (
        ``
      )}
    </div>
  );
};

export default NavbarAlerts;

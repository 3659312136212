export async function formatArr(arr) {
  var infoArray = arr;

  infoArray.records.forEach((obj) => {
    if (!obj.MDT_AC || obj.MDT_AC === "") {
      obj.MDT_AC = "ZONA AIRTEK";
    }

    if (!obj.ESTATUS_MDT || obj.ESTATUS_MDT === "") {
      obj.ESTATUS_MDT = "SIN ESTATUS";
    }

    if (!obj.MDT || obj.MDT === "") {
      obj.MDT = "MDT SIN ASIGNAR";
    }

    if (!obj.NODO || obj.NODO === "") {
      obj.NODO = "OLT SIN ASIGNAR";
    }
  });

  return infoArray;
}

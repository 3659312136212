import { formatArr } from "./formatArr";

export async function filterCP(arr) {
  var arreglo_cp = arr;
  const filtrado = await formatArr(arreglo_cp);
  var infoArray = filtrado.records;

  // funcion filtrar x busqueda

  const filter = (obj, search) =>
    obj.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(search.toLowerCase())
      )
    );

  // ---- Oportunidades object ----- //

  // año y mes actual

  var current_year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  // filtrar por mes y año (cantidad)
  const filterByMonth = (arr, yearFilter, monthF) =>
    arr.filter(
      ({ REGISTRADO }) =>
        new Date(REGISTRADO).getMonth() + 1 === yearFilter &&
        new Date(REGISTRADO).getFullYear() === monthF
    );

  // filtrar por dia (cantidad)

  const filterByDay = (arr) =>
    arr.filter(
      ({ REGISTRADO }) =>
        new Date(REGISTRADO).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    );

  var op_json = [
    {
      id: 0,
      descripcion: "Oportunidades",
      diarias: filterByDay(infoArray).length,
      mes: filterByMonth(infoArray, month, current_year).length,
      mes_str: month,
      clientes: infoArray,
    },
  ];

  // ---- Planes object ----- //

  // listado de planes
  var planes = new Set();

  infoArray.forEach((element) => {
    planes.add(element.PLAN_SOLICITADO);
  });

  var planes_array = Array.from(planes);

  // array respuesta
  var planes_Detailed = [];

  for (let index = 0; index < planes_array.length; index++) {
    var ag_categoria = filter(infoArray, planes_array[index]);

    var obj = {
      id: index + 1,
      descripcion: planes_array[index],
      total: filter(infoArray, planes_array[index]).length,
      diarias: filterByDay(ag_categoria).length,
      clientes: ag_categoria,
      plan: true,
    };

    planes_Detailed.push(obj);
  }

  // ---- MDT Status object ----- //

  // listado de status mdt
  var status_mdt = new Set();

  infoArray.forEach((element) => {
    status_mdt.add(element.ESTATUS_MDT);
  });

  var mdt_array = Array.from(status_mdt);

  // array respuesta
  var mdt_Detailed = [];

  for (let index = 0; index < mdt_array.length; index++) {
    var ag_categoria_mdt = filter(infoArray, mdt_array[index]);

    var obj_mdt = {
      id: index + planes_array.length + 1,
      descripcion: mdt_array[index],
      total: filter(infoArray, mdt_array[index]).length,
      diarias: filterByDay(ag_categoria_mdt).length,
      clientes: ag_categoria_mdt,
    };

    mdt_Detailed.push(obj_mdt);
  }

  // Respuesta a enviar
  var respuesta_json = {
    oportunidades: op_json,
    planes: planes_Detailed,
    mdt: mdt_Detailed,
  };

  return respuesta_json;
}

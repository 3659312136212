import React, { useState } from "react";
import video from "../../assets/videos/pwd-vid.mp4";
import "./CambiarPwd.css";
import vector from "../../assets/svg/back.svg";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../../components/shared/Button/Button";
import Modal1 from "../../components/ModalB/ModalB";
import ModalB from "../../components/ModalB/ModalB";
import Error403 from "../Error403/Error403";
import axios from "axios";
import Cookies from "universal-cookie";

const CambiarPwd = () => {
  const navigate = useNavigate();

  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));

  const validationSchema = Yup.object().shape({
    Password: Yup.string()
      .required('El campo "Contraseña actual" es requerido.      *')
      .min(8, "Su contraseña debe tener al menos 8 caracteres.")
      .max(
        12,
        'El campo "Contraseña actual" tiene un límite máximo de 12 caracteres.'
      ),
    Re_Password: Yup.string()
      .required('El campo "Nueva contraseña" es requerido.      *')
      .min(8, "Su contraseña nueva debe tener al menos 8 caracteres.")
      .max(
        12,
        'El campo "Nueva contraseña" tiene un límite máximo de 12 caracteres.'
      )
      .notOneOf(
        [Yup.ref("Password")],
        "La contraseña nueva debe ser distinta de la contraseña anterior."
      )
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "La contraseña debe tener al menos 1 número, 1 carácter especial, 1 carácter en MAYÚSCULAS y 1 carácter en minúscula."
      ),
    Re_Password2: Yup.string()
      .required('El campo "Confirmar contraseña" es requerido.      *')
      .max(
        12,
        'El campo "Confirmar contraseña" tiene un límite máximo de 12 caracteres.'
      )
      .oneOf(
        [Yup.ref("Re_Password")],
        'Los campos "Nueva Contraseña" y "Confirmar Contraseña" no coinciden.'
      ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const redirect = () => {
    navigate("/dashboard");
  };

  const cookies = new Cookies();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [sent, setSent] = useState(false);
  const [showButtons, setButtons] = useState(false);
  const [next, setNext] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));
  const [isLoading, setIsLoading] = useState(false);

  const Open = () => {
    setModal(!modal);

    if (sent === true) {
      redirect();
    }
  };

  const Open2 = () => {
    setModal2(!modal2);
  };

  const cambiar = () => {
    setMsj("¿Desea cambiar su contraseña?");
    setButtons(true);
    setIcon(`fas fa-exclamation-circle modal-icon-success fa-3x`);
    Open2();
    setNext(true);
  };

  const [msj, setMsj] = useState("");
  const [icon, setIcon] = useState("");

  const onSubmit = (data) => {
    if (data) {
      cambiar();

      if (next) {
        setTimeout(() => {
          Open2();
        }, 660);

        let buff = new Buffer(localStorage.getItem("username"), "base64");
        let base64ToStringNew = buff.toString("ascii");

        data = { ...data, UserName: base64ToStringNew, TipoConsulta: "2" };

        setIsLoading(true);

        axios
          .post(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_CAMBIAR_PWD}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
          .then(function (response) {
            setIsLoading(false);
           
            if (response.status === 204) {
              setSent(true);
              setMsj("La contraseña se ha cambiado exitosamente.");
              setIcon(`fas fa-check-circle modal-icon-success fa-3x`);

              Open();
            }
            if (response.status === 400) {
              setMsj("Hubo un percance con los datos enviados.");
              setIcon(`fa-solid fa-circle-xmark-icon-error fa-3x`);
              setNext(false);
              Open();
            }
            if (response.status === 404) {
              setMsj(
                "La contraseña actual que especificaste es incorrecta. Inténtalo de nuevo."
              );
              setIcon(`fa-solid fa-circle-xmark modal-icon-error fa-3x`);
              setNext(false);
              Open();
            }

          })
          .catch(function (error) {
            setIsLoading(false);
            setMsj("Hubo un percance con los datos enviados.");
            setIcon(`fa-solid fa-circle-xmark modal-icon-error fa-3x`);
            setNext(false);
            Open();
          });
      }
    }
  };

  return (
    <>
      {isLoggedIn === true ? (
        <>
          <nav className="navbar npwd">
            <div className="container ct">
              <div className="d-flex nvb-pwd">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="regresar"
                >
                  <img
                    src={vector}
                    alt="ícono regresar"
                    className="regresar-icon me-3"
                  />
                  Regresar
                </button>
              </div>
            </div>
          </nav>
          <div className="container ct">
            <div className="row gx-1">
              <div className="col-lg-6 p-left">
                <div className="d-flex l-container">
                  <div className="my-auto mx-auto">
                    <h6 className="rec-pwd">
                      Restablecer <br />
                      contraseña
                    </h6>
                    <p className="p-pwd">
                      Ingrese los valores solicitados para proceder con el
                      <span className="fw-bold"> cambio de clave.</span>
                    </p>

                    <div className="i-container">
                      <div
                        className={`input-group2 ${
                          errors.Password ? "is-invalid" : ""
                        }`}
                      >
                        <input
                          placeholder="Contraseña actual"
                          type="password"
                          {...register("Password")}
                          className={`input-pwd ${
                            errors.Password ? "is-invalid" : ""
                          }`}
                        />
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text-pwd ${
                              errors.Password ? "is-invalid" : ""
                            }`}
                          >
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                              <span className="fa-stack-1x numb">1</span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <ErrorMessage
                        errors={errors}
                        name="Password"
                        className="lg-error1"
                        as="p"
                      />

                      <div
                        className={`input-group2 ${
                          errors.Re_Password ? "is-invalid" : ""
                        }`}
                      >
                        <input
                          placeholder="Nueva contraseña"
                          type="password"
                          {...register("Re_Password")}
                          className={`input-pwd ${
                            errors.Re_Password ? "is-invalid" : ""
                          }`}
                        />
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text-pwd ${
                              errors.Re_Password ? "is-invalid" : ""
                            }`}
                          >
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                              <span className="fa-stack-1x numb">2</span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <ErrorMessage
                        errors={errors}
                        name="Re_Password"
                        className="lg-error1"
                        as="p"
                      />

                      <div
                        className={`input-group2 ${
                          errors.Re_Password2 ? "is-invalid" : ""
                        }`}
                      >
                        <input
                          placeholder="Confirmar contraseña"
                          type="password"
                          {...register("Re_Password2")}
                          className={`input-pwd ${
                            errors.Re_Password2 ? "is-invalid" : ""
                          }`}
                        />
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text-pwd ${
                              errors.Re_Password2 ? "is-invalid" : ""
                            }`}
                          >
                            <span className="fa-stack">
                              <i className="fas fa-circle fa-stack-2x"></i>
                              <span className="fa-stack-1x numb">3</span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <ErrorMessage
                        errors={errors}
                        name="Re_Password2"
                        className="lg-error1"
                        as="p"
                      />

                      <Button
                        text={"Cambiar Contraseña"}
                        myClass={"btn btn-dark rounded-pill btn-rec"}
                        funct={handleSubmit(onSubmit)}
                        loading={isLoading}
                        loadingMsg={"Cambiando Contraseña"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-none d-lg-block bg-right d-flex">
                <div className="d-flex">
                  <video autoPlay loop muted className="my-auto pwd-vid">
                    <source src={video} type="video/mp4" />
                    Su navegador no soporta la etiqueta de vídeo.
                  </video>
                </div>
              </div>
            </div>
            <div className="row ft-pwd">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                <p className="mb-0 pwd-f l-1">
                  Tu clave de acceso es personal e intransferible.
                </p>
                <p className="mb-0 pwd-f l-2">
                  La contraseña debería tener como mínimo de 8 a 12 caracteres
                  alfanuméricos.
                </p>
                <p className="mb-0 pwd-f l-3">
                  Evita utilizar palabras o nombres que sean fáciles de
                  adivinar.
                </p>
              </div>
            </div>
            <Modal1 open={modal} msj={msj} close={Open} icon={icon} />

            <ModalB
              open={modal2}
              msj={msj}
              btn1={"cambiar"}
              close={Open2}
              icon={icon}
              buttons={showButtons}
              funct={handleSubmit(onSubmit)}
              funct2={Open2}
            />
          </div>
        </>
      ) : (
        <Error403 />
      )}
    </>
  );
};

export default CambiarPwd;

import React from "react";
import NavbarAlerts from "./NavbarAlerts";
import "./Navbar.css";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  let navigate = useNavigate();
  const cookies = new Cookies();

  function logout() {
    navigate("/", { replace: true });
    cookies.remove('tkn', { path: '/' });
    localStorage.clear();
  }

  return (
    <div className="main-nb">
      <nav className="navbar navbar-m mb-3 mt-3">
        <div className="container-fluid p-0">
          <div className="d-flex">
            <div className="d-flex  justify-content-between align-items-center"></div>
          </div>

          <div className="ms-auto d-flex align-items-center">
            <div className="d-flex">
              <NavbarAlerts icon={"fas fa-sign-out-alt "} funct={logout} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

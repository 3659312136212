import React from "react";
import error from "../../assets/svg/error404.svg";
import { Link } from "react-router-dom";
import "./Error404.css";

const Error403 = () => {
  return (
    <div className="container vh-100">
      <div className="row h-100">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex h-100 text-center">
            <div className="mx-auto my-auto">
              <div>
                <h1 className="error404">Oops Error</h1>
                <p className="error404-txt">
                  Esta página web no está disponible.
                </p>
                <Link to="/" className="btn btn-dark rounded-pill ps-3 pe-3">
                  Ir al inicio
                </Link>
              </div>
              <img src={error} alt="error" className="img-fluid pt-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;

import React from "react";
import "./Status.css";

const Status = ({ title }) => {
  const title_class =
    title === "EN OPERACION"
      ? "bg-success"
      : title === "EXPLORACION COMERCIAL"
      ? "bg-primary"
      : title === "EN CONSTRUCCION"
      ? "bg-orange"
      : title === "SIN ESTATUS"
      ? "bg-unavailable"
      : title === "DISEÑO CERRADO"
      ? "bg-nd"
      : "bg-default";

  return (
    <div>
      <span className={`badge badge-status rounded-pill ${title_class}`}>
        {title}
      </span>
    </div>
  );
};

export default Status;

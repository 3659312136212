import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import TextArea from "../../components/shared/TextArea/TextArea";
import checkicon from "../../assets/svg/icon-success.svg";
import erroricon from "../../assets/svg/error-icon.svg";
import "./Usuarios.css";

const AgregarUser = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Roles: "",
      Codigo_ac: "",
    },
  });

  const [roles, setRoles] = useState();
  const [aliados, setAliados] = useState();
  const [reqstatus, setreqstatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsj, seterrorMsj] = useState();
  const [showElement, setshowElement] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    data["Usergen"] = base64ToStringNew;

    fetch(`${process.env.REACT_APP_URL}crear-usuario`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usuario: data,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);

        var mensajes_Err = [];

        if (json.status === 400) {
          var msjs = JSON.parse(json.msj);
          

          if (typeof msjs === "object") {
            msjs.forEach((element) => {
              element.Messages.forEach((el) => {
                mensajes_Err.push(`${element.Key}: ` + el);
              });
            });

            seterrorMsj(mensajes_Err);
          } else {
            seterrorMsj(msjs);
          }

          setshowElement(true);
          setTimeout(() => {
            setshowElement(false);
          }, 6000);
        } else {
          reset();
        }

        setreqstatus(json.status);
      })
      .catch((error) => {
        setIsLoading(false);
        reset();
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const getData = () => {
      fetch(`${process.env.REACT_APP_URL}roles`)
        .then((response) => response.json())
        .then((json) => {
          var list = [];

          json.forEach((obj) => {
            // get select option
            list.push({
              label: obj.descripcion,
              value: obj.codigo,
            });
          });

          setRoles(list);
        });

      fetch(`${process.env.REACT_APP_URL}aliados`)
        .then((response) => response.json())
        .then((json) => {
          var lista_aliados = [];

          json.forEach((obj) => {
            // get select option
            lista_aliados.push({
              label: obj.descripcion,
              value: obj.codigo_aliado,
            });
          });

          setAliados(lista_aliados);
        });
    };

    getData();
  }, []);

  function refresh_page() {
    window.location.reload();
  }

  return (
    <div className="mx-auto">
      <div className="modal-content modal1">
        <div className="modal-header mhdr"></div>

        <div className="modal-body mbdy">
          {reqstatus === 0 || reqstatus === 400 ? (
            <form>
              {/* <div className="mb-4">
                <h2 className="aliados-add">Agregar Usuario</h2>
              </div> */}

              {reqstatus === 400 && showElement === true && (
                <div className="w-100 d-flex flex-column mb-4 error-ac">
                  <img
                    src={erroricon}
                    alt="ícono error"
                    className="img-fluid mx-auto mb-1"
                    width={30}
                    height={20}
                  />
                  {errorMsj &&
                    (typeof errorMsj === "object" ? (
                      errorMsj.map((comment, index) => (
                        <p
                          key={index}
                          className="lg-error fw-bold ps-0 mx-auto text-center"
                        >
                          {comment}
                        </p>
                      ))
                    ) : (
                      <>
                        <p className="lg-error fw-bold ps-0 mx-auto text-center">
                          {errorMsj}
                        </p>
                      </>
                    ))}
                </div>
              )}

              <div className="d-flex aliados-wrp w-100">
                <div className="mb-3 w-50 ac-group">
                  <Input
                    fieldName="Nombre"
                    register={register}
                    errors={errors}
                    placeHolder="Nombre"
                    isRequired={true}
                    maximLength={50}
                    minimLength={2}
                  />
                </div>

                <div className="mb-3 w-50 ac-group ms-4">
                  <Input
                    fieldName="Apellido"
                    register={register}
                    errors={errors}
                    placeHolder=" Apellido"
                    isRequired={true}
                    maximLength={50}
                    minimLength={2}
                  />
                </div>
              </div>

              <div className="d-flex aliados-wrp w-100">
                <div className="mb-3 w-50 ac-group">
                  <Input
                    fieldName="Usuario"
                    register={register}
                    errors={errors}
                    placeHolder="Usuario"
                    isRequired={true}
                    maximLength={60}
                    minimLength={8}
                  />
                </div>

                <div className="mb-3 w-50 ac-group ms-4">
                  <Input
                    fieldName="Correo"
                    register={register}
                    errors={errors}
                    placeHolder="Correo"
                    isRequired={true}
                    isEmail={true}
                    maximLength={55}
                    minimLength={2}
                  />
                </div>
              </div>

              <div className="d-flex aliados-wrp w-100">
                <div className="mb-3 w-50 ac-group">
                  <select
                    className="form-select  agr-select rounded-pill border-secondary"
                    name="Roles"
                    {...register("Roles", {
                      required: {
                        value: true,
                        message: "El campo Roles es requerido *.",
                      },
                    })}
                  >
                    <option value="" disabled>
                      Roles
                    </option>

                    {roles &&
                      roles.map((comment) => (
                        <option key={comment.value} value={comment.value}>
                          {comment.label}
                        </option>
                      ))}
                  </select>
                  {errors.Roles && (
                    <p className="lg-error"> {errors.Roles.message}</p>
                  )}
                </div>

                <div className="mb-3 w-50 ac-group ms-4 d-flex flex-column">
                  <select
                    className="form-select  agr-select rounded-pill border-secondary"
                    name="Codigo_ac"
                    {...register("Codigo_ac", {
                      required: {
                        value: true,
                        message: "El campo Aliado Comercial es requerido *.",
                      },
                    })}
                  >
                    <option value="" disabled>
                      Aliado Comercial
                    </option>

                    {aliados &&
                      aliados.map((comment) => (
                        <option key={comment.value} value={comment.value}>
                          {comment.label}
                        </option>
                      ))}
                  </select>
                  {errors.Codigo_ac && (
                    <p className="lg-error"> {errors.Codigo_ac.message}</p>
                  )}
                </div>
              </div>

              <div className="d-flex aliados-wrp w-100">
                <div className="mb-3 w-50 ac-group">
                  <Input
                    fieldName="Password"
                    register={register}
                    errors={errors}
                    isPassword={true}
                    placeHolder="Contraseña"
                    isRequired={true}
                    maximLength={12}
                    minimLength={8}
                  />
                </div>

                <div className="mb-3 w-50 ac-group ms-4 d-flex flex-column">
                  <Input
                    fieldName="Re_Password"
                    register={register}
                    errors={errors}
                    watch={watch}
                    isPassword={true}
                    matchField={true}
                    placeHolder="Confirmar contraseña"
                    isRequired={true}
                    maximLength={12}
                    minimLength={8}
                  />
                </div>
              </div>

              <div className="d-flex aliados-wrp w-100">
                <div className="mb-3 w-50 ac-group">
                  <Input
                    fieldName="Telefono"
                    register={register}
                    errors={errors}
                    placeHolder="Teléfono"
                    isRequired={true}
                    isNumerical={true}
                    maximLength={11}
                    minimLength={10}
                  />
                </div>

                <div className="mb-3 w-50 ac-group ms-4">
                  <Input
                    fieldName="Comentario"
                    register={register}
                    errors={errors}
                    placeHolder="Comentario"
                    isRequired={false}
                    maximLength={100}
                    minimLength={2}
                  />
                </div>
              </div>

              <div className="d-flex flex-column">
                <p className="mx-auto mb-0 agr-ubicacion">Ubicación</p>
                <hr className="separator" />

                <div className="d-flex aliados-wrp w-100">
                  <div className="mb-3 w-100 ac-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Dirección
                    </label>
                    <TextArea
                      fieldName="Direccion"
                      register={register}
                      errors={errors}
                      placeHolder="Av. 4 Bella Vista entre calles 68 y 69. Maracaibo, Edo. Zulia."
                      isRequired={false}
                      maximLength={150}
                      minimLength={5}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex w-100">
                <div className="mx-auto">
                  <Button
                    text={"Guardar Datos"}
                    myClass={"btn btn-save pe-4 ps-4"}
                    funct={handleSubmit(onSubmit)}
                    loading={isLoading}
                    loadingMsg={"Guardando Datos..."}
                  />
                </div>
              </div>
            </form>
          ) : (
            <div className="text-center">
              <div className="d-flex">
                {reqstatus === 204 ? (
                  <div className="w-100">
                    <img
                      src={checkicon}
                      alt="ícono check"
                      className="img-fluid"
                    />
                    <p className="ac-guardado">
                      ¡Los datos del usuario se han guardado exitosamente!
                    </p>
                    <Button
                      text={"Regresar"}
                      myClass={"btn btn-save pe-4 ps-4"}
                      funct={refresh_page}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="w-100">
                      <img
                        src={erroricon}
                        alt="ícono error"
                        className="img-fluid"
                      />
                      <p className="modal-error">
                        Se produjo un error. Vuelve a intentarlo más tarde.
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgregarUser;

import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const tkn = cookies.get("tkn");

export async function formatArrCP1(arr) {
  var infoArray = arr;

  infoArray.forEach((obj) => {
    if (!obj.MDT_AC || obj.MDT_AC === "") {
      obj.MDT_AC = "ZONA AIRTEK";
    }

    if (!obj.ESTATUS_MDT || obj.ESTATUS_MDT === "") {
      obj.ESTATUS_MDT = "SIN ESTATUS";
    }

    if (!obj.MDT || obj.MDT === "") {
      obj.MDT = "MDT SIN ASIGNAR";
    }

    if (!obj.NODO || obj.NODO === "") {
      obj.NODO = "OLT SIN ASIGNAR";
    }
  });

  // - Agrupar clientes potenciales x Aliado -

  var cp_filtered = {};

  const filter = (obj, search) =>
    obj.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(search.toLowerCase())
      )
    );

  for (let index = 0; index < infoArray.length; index++) {
    if (
      infoArray[index].MDT_AC !== "ZONA AIRTEK" &&
      infoArray[index].ESTATUS_MDT === "EN OPERACION"
    ) {
      cp_filtered[infoArray[index].MDT_AC] = filter(
        infoArray,
        infoArray[index].MDT_AC
      );
    }
  }

  // - Convertir objeto a Array -
  const entries = Object.entries(cp_filtered);

  // - Obtener info de los Aliados -
  async function getData() {
    if (tkn) {
      var response;

      await axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ALIADOS_ACTV}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          response = res.data;
        })
        .catch((error) => {
          console.error(error);
        });

      return response;
    }
  }

  const data1 = await getData();

  // - Agregar correo / cod_Ac a cada aliado -

  if (data1 && data1.length > 0) {
    entries.forEach((element) => {
      for (let index = 0; index < data1.length; index++) {
        if (element[1][0].MDT_COD_AC === data1[index].codigo_aliado) {
          element.push(data1[index].email, element[1][0].MDT_COD_AC);
        }
      }
    });
  }

  // - Keys de cada objeto / convertir a array -
  const keys_ac = ["descripcion", "clientesp", "email", "cod_ac"];

  var arr_ac = [];

  entries.forEach((element) => {
    var obj3 = {};
    element.forEach((data, index) => {
      obj3[keys_ac[index]] = data;
    });
    arr_ac.push(obj3);
  });

  // - Eliminar item que no tenga cod_Ac || email -

  for (let j = 0; j < arr_ac.length; j++) {
    if ("email" in arr_ac[j] === false && "cod_ac" in arr_ac[j] === false) {
      arr_ac.splice(j, 1);
    }
  }

  return arr_ac;
}

export async function getIndicadores(arr) {
  var planes = new Set();

  arr.forEach((element) => {
    planes.add(element.NombreGrupo);
  });

  var planes_array = Array.from(planes);

  const filter = (obj, search) =>
    obj.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(search.toLowerCase())
      )
    );

  var array_resp = [];

  for (let index = 0; index < planes_array.length; index++) {
    var ag_categoria = filter(arr, planes_array[index]);

    var qty = 0;

    for (let i = 0; i < ag_categoria.length; i++) {
      qty = qty + ag_categoria[i].Cantidad;
    }

    var obj = {
      grupo: planes_array[index],
      detalle: ag_categoria,
      total_grupo: qty,
    };

    array_resp.push(obj);
  }

  return array_resp;
}

import React, { useState } from "react";
import sorticon from "../../../assets/svg/sort-icon.svg";
import "./TableHeader.css";

const TableHeader = ({
  headers,
  onSorting,
  id,
  type,
  handleClick,
  isChecked,
}) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <thead className="text-center">
      <tr>
        {headers.map(({ name, field, sortable, check, hidden }) => (
          <th
            key={name}
            className={` ${hidden === true ? "d-none" : ""}`}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}

            {check && (
              <>
                <input
                  id={id}
                  name={name}
                  type={type}
                  onChange={handleClick}
                  checked={isChecked}
                />
              </>
            )}

            {sortingField && sortingField === field && (
              <img
                src={sorticon}
                alt="sort icon"
                className={`ms-2 ${
                  sortingOrder === "desc" ? "icon-desc" : "icon-asc"
                }`}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

import React, { useState } from "react";
import "./RecoverPwd.css";
import "./ResetPwd.css";
import logodark from "../../assets/svg/logo-dark.svg";
import Button from "../../components/shared/Button/Button";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Modal1 from "../../components/Modal/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import emailicon from "../../assets/svg/email-icon.svg";
import passwordicon from "../../assets/svg/password-icon.svg";
import { useNavigate } from "react-router-dom";

const RecoverPwd = () => {
  const [check, setcheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [modalText, setmodalText] = useState("");
  const [Icon, setIcon] = useState("");
  const [MyClass, setClass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setemail] = useState("");
  const [verPass, setverPass] = useState(false);
  const [verPass2, setverPass2] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    Nick: Yup.string(),
    Usuario_modificacion: Yup.string(),

    Password: Yup.string()
      .required('El campo "Contraseña" es requerido.      *')
      .min(8, "Su contraseña debe tener al menos 8 caracteres.")
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "La contraseña debe tener al menos 1 número, 1 carácter especial, 1 carácter en MAYÚSCULAS y 1 carácter en minúscula."
      )
      .max(
        12,
        'El campo "Contraseña" tiene un límite máximo de 25 caracteres.'
      ),

    Re_Password: Yup.string()
      .required('El campo "Confirmar contraseña" es requerido.      *')
      .min(
        8,
        'El campo "Confirmar contraseña" debe tener al menos 8 caracteres.'
      )
      .max(
        12,
        'El campo "Confirmar contraseña" tiene un límite máximo de 25 caracteres.'
      )
      .oneOf(
        [Yup.ref("Password")],
        'Los campos "Contraseña" y "Confirmar contraseña" no coinciden.'
      )
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "La contraseña debe tener al menos 1 número, 1 carácter especial, 1 carácter en MAYÚSCULAS y 1 carácter en minúscula."
      ),
    Codigo_confirmacion: Yup.string()
      .required('El campo "Código de Verificación" es requerido.      *')
      .min(
        10,
        'El campo "Código de Verificación" debe tener al menos 10 caracteres.'
      )
      .max(
        10,
        'El campo "Código de Verificación" tiene un límite máximo de 10 caracteres.'
      ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm(formOptions);

  // modal
  const Open = () => {
    setModal(!modal);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_URL}recover`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email: data.email,
        },
      }),
    })
      .then((res) => res.text())
      .then((json) => {
        setIsLoading(false);
        if (json.error) {
          throw new Error("Bad response from server");
        }
        if (json === "204") {
          setemail(data.email);
          Open();
          setIcon("fa-solid fa-circle-check fa-lg modal-icon-success icon-m");
          setClass("modal-icon-success");
          setmodalTitle("¡Datos Enviados!");
          setmodalText(
            "Recibirás un enlace para crear la contraseña nueva por correo electrónico."
          );
          setTimeout(() => {
            setcheck(true);
          }, 1000);
        } else {
          Open();
          setIcon("fa-solid fa-circle-xmark fa-lg modal-icon-error icon-m");
          setClass("modal-icon-error");
          setmodalTitle("Por favor verifique los datos Correo incorrecto");
          setmodalText("");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onSubmitPwd = (info) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_URL}recovery-pass`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pwd: info,
      }),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 204) {
          Open();
          setIcon("fa-solid fa-circle-check fa-lg modal-icon-success icon-m");
          setClass("modal-icon-success");
          setmodalTitle("¡Datos Enviados!");
          setmodalText([
            <p>
              Su contraseña se ha cambiado, <Link to="/"> inicie sesión</Link>{" "}
              para continuar
            </p>,
          ]);
          setTimeout(() => {
            return navigate("/");
          }, 2000);
        } else if (res.status === 512) {
          Open();
          setIcon("fa-solid fa-circle-xmark fa-lg modal-icon-error icon-m");
          setClass("modal-icon-error");
          setmodalTitle("Su contraseña es similar a la anterior.");
          setmodalText("");
        } else {
          Open();
          setIcon("fa-solid fa-circle-xmark fa-lg modal-icon-error icon-m");
          setClass("modal-icon-error");
          setmodalTitle("Hubo un percance con los datos enviados.");
          setmodalText("");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex h-100 text-center">
            <div className="mx-auto my-auto recuperar">
              <div className="recuperar-w">
                <img
                  src={logodark}
                  className="logo-dark mb-4 img-fluid logo-recuperar"
                  alt="logo airtek"
                />
                {check === false ? (
                  <div className="w-100">
                    <p className="recup-pwd">
                      Por favor, ingresa el correo electrónico para recuperar tu
                      contraseña.
                    </p>

                    <div className="input-group">
                      <span
                        className={`input-group-text-rec ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      >
                        <img src={emailicon} alt="ícono correo" />
                      </span>
                      <input
                        type="text"
                        name="email"
                        className={`ps-0 form-control-rec ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Correo Registrado"
                        {...register("email", {
                          required:
                            "El campo correo registrado es obligatorio *",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message:
                              "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve).",
                          },
                        })}
                      />
                    </div>

                    <ErrorMessage
                      errors={errors}
                      className="lg-error"
                      name="email"
                      as="p"
                    />

                    <Button
                      text={"Recuperar Contraseña"}
                      myClass={"btn btn-dark rounded-pill w-100 btn-recuperar"}
                      funct={handleSubmit(onSubmit)}
                      loading={isLoading}
                      loadingMsg={"Validando datos"}
                    />

                    <div className="mt-4">
                      <Link to="/" className="iniciar-s">
                        Iniciar Sesión
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="i-container">
                      <div className="input-group mt-1">
                        <span
                          className={`input-group-text-rec ${
                            errors2.Password ? "is-invalid" : ""
                          }`}
                        >
                          <img src={passwordicon} alt="ícono contraseña" />
                        </span>
                        <input
                          type={verPass ? "text" : "password"}
                          className={` border-end-0 ps-0 form-control-rec ${
                            errors2.Password ? "is-invalid" : ""
                          }`}
                          placeholder="Contraseña"
                          {...register2("Password")}
                        />

                        <span
                          className={`input-group-text-lg  ${
                            errors2.Password ? "is-invalid" : ""
                          }`}
                        >
                          <i
                            className={
                              verPass
                                ? "fa-solid fa-eye eye-pwd"
                                : "fa-solid fa-eye-slash eye-pwd"
                            }
                            onClick={() => setverPass(!verPass)}
                          ></i>
                        </span>
                      </div>

                      <ErrorMessage
                        errors={errors2}
                        name="Password"
                        className="lg-error1"
                        as="p"
                      />

                      <div className="input-group mt-3">
                        <span
                          className={`input-group-text-rec ${
                            errors2.Re_Password ? "is-invalid" : ""
                          }`}
                        >
                          <img src={passwordicon} alt="ícono contraseña" />
                        </span>
                        <input
                          type={verPass2 ? "text" : "password"}
                          className={`border-end-0 ps-0 form-control-rec ${
                            errors2.Re_Password ? "is-invalid" : ""
                          }`}
                          placeholder="Confirmar contraseña"
                          {...register2("Re_Password")}
                        />

                        <span
                          className={`input-group-text-lg  ${
                            errors2.Re_Password ? "is-invalid" : ""
                          }`}
                        >
                          <i
                            className={
                              verPass2
                                ? "fa-solid fa-eye eye-pwd"
                                : "fa-solid fa-eye-slash eye-pwd"
                            }
                            onClick={() => setverPass2(!verPass2)}
                          ></i>
                        </span>
                      </div>

                      <ErrorMessage
                        errors={errors2}
                        name="Re_Password"
                        className="lg-error1"
                        as="p"
                      />

                      <div className="pt-4">
                        <label className="fw-bold">
                          Código de Verificación
                        </label>

                        <div>
                          <input
                            placeholder="Código de Verificación"
                            type="text"
                            {...register2("Codigo_confirmacion")}
                            className={`email-input text-center mb-0 ${
                              errors2.Codigo_confirmacion ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>

                      <input
                        placeholder="Código de Verificación"
                        type="password"
                        {...register2("Usuario_modificacion")}
                        className="d-none"
                        value={email}
                      />

                      <input
                        placeholder="Código de Verificación"
                        type="password"
                        {...register2("Nick")}
                        className="d-none"
                        value={email}
                      />

                      <ErrorMessage
                        errors={errors2}
                        name="Codigo_confirmacion"
                        className="lg-error1"
                        as="p"
                      />

                      <div className="mt-4">
                        <Button
                          text={"Cambiar Contraseña"}
                          myClass={"btn btn-dark rounded-pill btn-rec"}
                          funct={handleSubmit2(onSubmitPwd)}
                          loading={isLoading}
                          loadingMsg={"Cambiando contraseña"}
                        />
                      </div>
                      <div className="mt-4">
                        <Link to="/" className="iniciar-s">
                          Iniciar Sesión
                        </Link>
                      </div>

                      <div className="row ft-pwd recover-pwd-f mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-start">
                          <p className="mb-2 pwd-f l-1 recover-p">
                            <span className="fw-bold"> Nota: </span>Para generar
                            la contraseña debe tener una combinación de los
                            siguientes caracteres:
                          </p>

                          <ul className="mb-0">
                            <li className="mb-0 pwd-f l-2 recover-p">
                              Incluir caracteres alfanuméricos.
                            </li>
                            <li className="mb-0 pwd-f l-2 recover-p">
                              Incluir al menos un símbolo especial (?.+@/#%)
                            </li>
                            <li className="mb-0 pwd-f l-2 recover-p">
                              Mínimo de 8 y máximo de 12 caracteres.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal1
        open={modal}
        close={() => Open()}
        modalType={3}
        modalTitle={modalTitle}
        modalText={modalText}
        icon={Icon}
        title_class={MyClass}
      />
    </div>
  );
};

export default RecoverPwd;

import React from "react";
import { Link } from "react-router-dom";
import "./Error403.css";

const Error403 = () => {
  return (
    <div className="container vh-100">
      <div className="row h-100">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex h-100 text-center">
            <div className="mx-auto my-auto">
              <div>
                <i className="fa-solid fa-lock lock-icon"></i>
                <h1 className="error403">Acceso denegado</h1>
                <p className="error403-txt">
                  La página a la que intenta acceder tiene acceso restringido.
                </p>
                <Link to="/" className="btn btn-dark rounded-pill ps-3 pe-3">
                  Ir al inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;

import React, { useState } from "react";
import Select from "react-select";
import "./Calendar.css";

import "./DateSelector.css";

const DateSelector = ({ onSearch, op, custom, tableA }) => {
  const [search, setSearch] = useState("");

  const options = [
    {
      label: "Personalizado",
      value: "Personalizado",
    },
    {
      label: "Hoy",
      value: "Hoy",
    },
    {
      label: "Ayer",
      value: "Ayer",
    },
    {
      label: "Esta semana",
      value: "Esta semana",
    },
    {
      label: "Este mes",
      value: "Este mes",
    },
    {
      label: "Este año",
      value: "Este año",
    },
  ];

  const optionsTA = [
    {
      label: "Hoy",
      value: "Hoy",
    },
    {
      label: "Ayer",
      value: "Ayer",
    },
    {
      label: "Esta semana",
      value: "Esta semana",
    },
    {
      label: "Este mes",
      value: "Este mes",
    },
    {
      label: "Este año",
      value: "Este año",
    },
  ];

  const onInputChange = (e) => {
    if (e !== null) {
      setSearch(e.value);
      onSearch(e.value);
    } else {
      setSearch("");
      onSearch("");
    }
  };

  const customStyles = {
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#dadada ", // Custom colour
    }),
    clearIndicator: (base) => ({
      ...base,
      color: "#bababa ", // Custom colour
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#111", // Custom colour
      paddingLeft: 5,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "#111",
      opacity: 1,
      fontWeight: 400,
    }),
    control: (base) => ({
      ...base,
      padding: 0,
      fontSize: 11,
      opacity: 1,
      color: "#111",
      boxShadow: "none",
      border: "1px solid #C4C4C4",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#FFF" : "#111",
      backgroundColor: state.isFocused ? "#0066FF" : null,
      padding: 8,
      fontSize: 11,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 1 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
      };
    },
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      border: "none",
      minHeight: "20px",
      height: "20px",
      textAlign: "center",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#FFF" : "#111",
      backgroundColor: state.isFocused ? "#0066FF" : null,
      padding: 8,
      fontSize: 10,
      // width: 200,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "#111",
      opacity: 1,
      fontWeight: 400,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "20px",
    }),
  };

  const customStyles3 = {
    clearIndicator: (base) => ({
      ...base,
      color: "#fff ",
      paddingBottom: "0px",
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#111",
      border: "none",
      minHeight: "20px",
      height: "20px",
      textAlign: "center",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#FFF" : "#111",
      backgroundColor: state.isFocused ? "#0066FF" : null,
      padding: 8,
      fontSize: 10,
      // width: 200,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "11px",
      background: "#111",
      color: "#fff",
      opacity: 1,
      fontWeight: 400,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#fff",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      "&:hover": {
        color: "#fff",
      },
    }),

    singleValue: (base) => ({
      ...base,
      fontSize: "11px",
      background: "#111",
      color: "#fff",
    }),

    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "20px",
    }),
  };

  return (
    <Select
      className={custom ? "select-wrp" : "select-date"}
      onChange={onInputChange}
      isSearchable={false}
      value={search.value}
      options={op === 1 ? optionsTA : options}
      placeholder={custom === true ? "7 Días " : "Rango de Fecha"}
      isClearable
      styles={
        custom === true
          ? customStyles2
          : tableA === true
          ? customStyles3
          : customStyles
      }
    />
  );
};

export default DateSelector;

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "./CardTabs.css";
import "swiper/swiper-bundle.min.css";
import Map from "../../components/shared/Map/Map";
import Card from "../../components/Card/Card";
import Cookies from "universal-cookie";
import axios from "axios";
import { filterCP } from "../../utils/functions/filterCP_Cards";

const CardTabs = ({ location }) => {
  SwiperCore.use([Navigation]);
  const cookies = new Cookies();

  const [first, setfirst] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [oportunidades, setoportunidades] = useState();
  const [mdt, setmdt] = useState();
  const [planes, setplanes] = useState();
  const [mapInfo, setmapInfo] = useState();
  const [isMDT, setisMDT] = useState(true);
  const [allData, setallData] = useState();
  const [tkn] = useState(cookies.get("tkn"));

  function clickMe(event, funct) {
    setfirst(funct);

    var datos = [];

    for (const key in allData) {
      datos.push(...allData[key]);
    }

    var result = datos.find((x) => x.id === funct);

    setmapInfo(result.clientes);

    if (result.plan === true) {
      setisMDT(false);
    } else {
      setisMDT(true);
    }
  }

  useEffect(() => {
    async function getData() {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CPOTENCIALES}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          const clientesp = await filterCP(res.data);

          setoportunidades(clientesp.oportunidades);
          setmdt(clientesp.mdt);
          setIsLoading(false);
          setallData(clientesp);
          setplanes(clientesp.planes);
          setmapInfo(clientesp.oportunidades[0].clientes);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    getData();
  }, [tkn]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {isLoading === false ? (
            <>
              {planes && oportunidades && mdt ? (
                <Swiper
                  navigation={true}
                  autoHeight={true}
                  spaceBetween={90}
                  slidesPerView={5}
                  slidesOffsetAfter={90}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    805: {
                      slidesPerView: 3,
                      spaceBetween: 80,
                    },
                    1100: {
                      slidesPerView: 4,
                      spaceBetween: 80,
                    },
                    1360: {
                      slidesPerView: 5,
                      spaceBetween: 90,
                    },
                  }}
                >
                  {oportunidades &&
                    oportunidades.map((d) => (
                      <SwiperSlide key={d.id}>
                        <Card
                          title={d.descripcion}
                          daily={d.diarias}
                          month={d.mes}
                          myClass={`card card-s${
                            d.id === first ? " active_item" : ""
                          }`}
                          funct={(e) => {
                            clickMe(e, d.id);
                          }}
                        />
                      </SwiperSlide>
                    ))}

                  {planes &&
                    planes.map((d) => (
                      <SwiperSlide key={d.id}>
                        <Card
                          title={d.descripcion}
                          planClass={
                            d.descripcion === "600 MEGAS"
                              ? "megas600"
                              : "megas50"
                          }
                          daily={d.diarias}
                          month={d.total}
                          myClass={`card card-s${
                            d.id === first ? " active_item" : ""
                          }`}
                          funct={(e) => {
                            clickMe(e, d.id);
                          }}
                        />
                      </SwiperSlide>
                    ))}

                  {mdt &&
                    mdt.map((d) => (
                      <SwiperSlide key={d.id}>
                        <Card
                          title={d.descripcion}
                          daily={d.diarias}
                          month={d.total}
                          myClass={`card card-s${
                            d.id === first ? " active_item" : ""
                          }`}
                          funct={(e) => {
                            clickMe(e, d.id);
                          }}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
            </>
          )}
        </div>
      </div>

      <div className="row mt-4 row-ctabs">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="tab-ct-wrapper">
            <div className="tab-content mb-3" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {mapInfo && (
                  <Map data={mapInfo} mdt={isMDT} coordsUser={location} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardTabs;

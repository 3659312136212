import React, { useEffect, useState, useMemo } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Pagination from "react-js-pagination";
import Search from "../../components/shared/SearchBar/SearchBar";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import Select1 from "../../components/shared/Select/Select";
import Status from "../../components/Status/Status";
import arrow_down from "../../assets/svg/arrow-down.svg";
import ExportExcel from "../../utils/export/ExportExcel/ExportExcel";
import ExportPDF from "../../utils/export/ExportPDF/ExportPDF";
import DateSelector from "../../components/DateSelector/DateSelector";
import "./ReasignacionMDT.css";
import ModalAsignar from "../../components/Modal/ModalAsignar/ModalAsignar";
import axios from "axios";
import Cookies from "universal-cookie";
import { formatMDT } from "../../utils/functions/formatMDT";
import Loader from "../../components/Loaders/Loader";

const ReasignacionMDT = () => {
  const [comments, setComments] = useState([]);
  const cookies = new Cookies();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [isLoading, setIsLoading] = useState(true);

  const [multipleSearch, setMultipleSearch] = useState({
    Ac: "",
    Estado: "",
    Nombre_mdt: "",
    Nodo: "",
  });

  const [dateFilter, setdateFilter] = useState("");
  const [downloadExcel, setExport] = useState("");
  const ITEMS_PER_PAGE = 7;
  const now = new Date();
  const yesterdayBegin = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );

  const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);
  const [modalAC, setModalAC] = useState(false);
  const [qtyNN, setQtyNN] = useState(0);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  const headers = [
    { name: "", field: "", sortable: false, check: true },
    {
      name: "Fecha de Asignación",
      field: "FechaInstalacion",
      sortable: true,
    },
    { name: "Aliado Comercial    ", field: "Ac", sortable: false },
    {
      name: "Aliado Comercial Reasignado   ",
      field: "Reasignado_Ac",
      sortable: false,
      hidden: true,
    },
    {
      name: "Última Actualización",
      field: "FechaReasignacion",
      sortable: false,
    },
    { name: "Realizado Por", field: "UserReasignacion", sortable: false },
    { name: "OLT", field: "Nodo", sortable: false },
    { name: "MDT", field: "Nombre_mdt", sortable: false },
    { name: "Estatus", field: "Estado", sortable: true },
  ];

  useEffect(() => {
    const getData = () => {

      axios
        .get(`${process.env.REACT_APP_API}${process.env.REACT_APP_MDT}`, {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        })
        .then(async (res) => {
          const p = await formatMDT(res.data);
          setIsLoading(false);
          setComments(p.records);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getData();
  }, [tkn]);

  const OpenAC = () => {
    setModalAC(!modalAC);
  };

  const asignar = () => {
    OpenAC();
  };

  // select all checkboxes | based on filter response

  const handleSelectAll = (e) => {
    const filterZonaAirtek = (arr) =>
      arr.filter((el) => {
        return (
          el.Ac !== "ZONA AIRTEK" &&
          el.Reasignado_Ac !== "ZONA AIRTEK" &&
          el.Ac !== "Zona Airtek" &&
          el.Reasignado_Ac !== "Zona Airtek"
        );
      });

    if (
      search === "" &&
      qtyNN === 0 &&
      Object.values(multipleSearch).every((x) => x === null || x === "") ===
        true
    ) {
      setIsCheckAll(!isCheckAll);
      setCheckedCheckboxes(filterZonaAirtek(comments));
    } else {
      setIsCheckAll(!isCheckAll);
      setCheckedCheckboxes(filterZonaAirtek(downloadExcel));
    }
    if (isCheckAll) {
      setCheckedCheckboxes([]);
    }
  };

  // select individual checkboxes
  const handleCheckboxChange = (comment) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox.Id === comment.Id
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.Id !== comment.Id
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(comment));
    }
  };

  // nodo
  const test = useMemo(
    () =>
      [...new Set(comments.map((item) => item.Nodo))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // status
  const options = useMemo(
    () =>
      [...new Set(comments.map((item) => item.Estado))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // mdt
  const mdt = useMemo(
    () =>
      [...new Set(comments.map((item) => item.Nombre_mdt))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  // mdt
  const aliados = useMemo(
    () =>
      [...new Set(comments.map((item) => item.Ac))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // Custom filter

    if (dateFilter === "Personalizado") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion) > value[0] &&
            new Date(FechaInstalacion) < value[1]
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter by year

    if (dateFilter === "Este año") {
      var p = new Date().getFullYear();
      const filterByDate = (arr, yearFilter) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion).getFullYear() === yearFilter
        );
      computedComments = filterByDate(computedComments, p);
    }

    // Filter by Month

    if (dateFilter === "Este mes") {
      var filter_year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;

      const filterByDate = (arr, yearFilter, monthF) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion).getMonth() + 1 === yearFilter &&
            new Date(FechaInstalacion).getFullYear() === monthF
        );

      computedComments = filterByDate(computedComments, month, filter_year);
    }

    // Filter by Week
    if (dateFilter === "Esta semana") {
      const getStartOfWeek = (date) => {
        date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - date.getDay());
        return date;
      };

      const getEndOfWeek = (date) => {
        date = getStartOfWeek(date);
        date.setDate(date.getDate() + 6);
        return date;
      };

      var inicio = getStartOfWeek();
      var final = getEndOfWeek();

      const filterByDate = (arr) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion) > inicio &&
            new Date(FechaInstalacion) < final
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter (Today)
    if (dateFilter === "Hoy") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // Filter (Yesterday)
    if (dateFilter === "Ayer") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FechaInstalacion }) =>
            new Date(FechaInstalacion).setHours(0, 0, 0, 0) ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            ).setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // check how many elements aren't empty inside multipleSearch
    var qty = 0;
    for (var key in multipleSearch) {
      if (multipleSearch[key] !== "") {
        qty = qty + 1;
      }
      setQtyNN(qty);
    }

    // Global search
    if (search !== "" && search !== null) {
      computedComments = computedComments.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    // search based on multiple categories

    if (
      qtyNN >= 1 &&
      Object.values(multipleSearch).every((x) => x === null || x === "") ===
        false
    ) {
      let check = {
        Estado: `${multipleSearch.Estado}`,
        Nombre_mdt: `${multipleSearch.Nombre_mdt}`,
        Ac: `${multipleSearch.Ac}`,
        Nodo: `${multipleSearch.Nodo}`,
      };

      // remove empty elements
      check = Object.fromEntries(
        Object.entries(check).filter(([_, v]) => v !== "")
      );

      // check inside records
      computedComments = computedComments.filter((result) =>
        Object.entries(check).every(([key, val]) =>
          val !== "" ? result[key] === val : true
        )
      );
    }

    setExport(computedComments);
    setTotalItems(computedComments.length);

    //Sorting rows
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Slice current Page
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    qtyNN,
    multipleSearch,
    dateFilter,
    value,
  ]);

  const TableInformation = ({ info }) => {
    return (
      <>
        {info.map((comment, index) => (
          <tr
            key={index}
            className={`${
              checkedCheckboxes.some(
                (checkedCheckbox) => checkedCheckbox.Id === comment.Id
              ) === true
                ? "row-selected"
                : ""
            }`}
          >
            <td>
              {comment.Ac !== "ZONA AIRTEK" &&
              comment.Reasignado_Ac !== "ZONA AIRTEK" &&
              comment.Ac !== "Zona Airtek" &&
              comment.Reasignado_Ac !== "Zona Airtek" ? (
                <input
                  key={`cb-${index}`}
                  value={comment.Id}
                  type="checkbox"
                  checked={checkedCheckboxes.some(
                    (checkedCheckbox) => checkedCheckbox.Id === comment.Id
                  )}
                  onChange={() => handleCheckboxChange(comment)}
                />
              ) : (
                ""
              )}
            </td>
            <td>{comment.FechaInstalacion.replace("T", " ")}</td>
            <td
              className={
                comment.Ac === "ZONA AIRTEK" || comment.Ac === "Zona Airtek"
                  ? "zona-airtek"
                  : ""
              }
            >
              {comment.Ac}
            </td>
            <td className="d-none">{comment.Reasignado_Ac}</td>
            <td>{comment.FechaReasignacion.replace("T", " ")}</td>
            <td>{comment.UserReasignacion}</td>
            <td>{comment.Nodo}</td>
            <td>{comment.Nombre_mdt}</td>
            <td>
              <Status title={comment.Estado} />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="ps-4 pe-4 pt-4">
      <div>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="col mb-3 col-12 text-center">
            <div className="row">
              <p className="asignacion-title">Tabla Asignación MDT</p>
              <div className="col-md-12 mt-1 mb-4 d-flex flex-row justify-content-between align-items-center  pe-4">
                <div className="d-flex w-100 justify-content-between filters-col">
                  <div
                    className="d-flex me-2 search-bar-tableb"
                    style={{ width: "286px" }}
                  >
                    <div className="dropdown me-1 ">
                      <button
                        className="btn btn-primary dropdown-toggle download-btn ps-1 pe-1 pt-1 pb-1"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={arrow_down} alt="ícono flecha" />
                      </button>
                      <ul
                        className="dropdown-menu drpdwn-export"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="pdf-list">
                          <ExportExcel data={downloadExcel} rep_t={2} />
                        </li>

                        <li className="pdf-list">
                          <ExportPDF data={downloadExcel} rep_t={2} />
                        </li>
                      </ul>
                    </div>

                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                      placeholder={"Buscar Por Nombre del Aliado"}
                    />
                  </div>

                  <div className="d-flex drpdwn-filters">
                    <div className="d-flex align-items-center me-3 drpd select-f">
                      <div className="btn-group">
                        <button
                          className="btn btn-light dropdown-toggle"
                          type="button"
                          id="dropdownMenuClickableInside"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          Seleccionar Fecha
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg ms-2"
                          >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                          </svg>
                        </button>
                        <ul
                          className="dropdown-menu pe-2 ps-2"
                          aria-labelledby="dropdownMenuClickableInside"
                        >
                          <DateSelector
                            onSearch={(value) => {
                              setdateFilter(value);
                              setCurrentPage(1);
                            }}
                            op={"2"}
                          />

                          {dateFilter === "Personalizado" && (
                            <DateRangePicker
                              calendarAriaLabel="Toggle calendar"
                              clearAriaLabel="Clear value"
                              dayAriaLabel="Day"
                              monthAriaLabel="Month"
                              nativeInputAriaLabel="Date"
                              clearIcon={null}
                              yearAriaLabel="Year"
                              format={"MM-dd-y"}
                              onChange={onChange}
                              value={value}
                              rangeDivider={"al"}
                            />
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="filters2 filtros-mdt d-flex ">
                      <div className="d-flex flex-column select-f">
                        <Select1
                          onSearch={(value) => {
                            setMultipleSearch({
                              ...multipleSearch,
                              Ac: value,
                            });
                            setCurrentPage(1);
                          }}
                          data={aliados}
                          qty={qtyNN}
                          placeholder={"Aliado"}
                        />
                      </div>

                      <div className="d-flex select-f">
                        <Select1
                          onSearch={(value) => {
                            setMultipleSearch({
                              ...multipleSearch,
                              Nodo: value,
                            });
                            setCurrentPage(1);
                          }}
                          data={test}
                          placeholder={"OLT"}
                        />
                      </div>
                      <div className="d-flex flex-column select-f">
                        <Select1
                          onSearch={(value) => {
                            setMultipleSearch({
                              ...multipleSearch,
                              Nombre_mdt: value,
                            });
                            setCurrentPage(1);
                          }}
                          data={mdt}
                          placeholder={"MDT"}
                        />
                      </div>

                      <div className="d-flex flex-column select-f">
                        <Select1
                          onSearch={(value) => {
                            setMultipleSearch({
                              ...multipleSearch,
                              Estado: value,
                            });

                            setCurrentPage(1);
                          }}
                          data={options}
                          placeholder={"Estatus"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-b table-mdt table-bordered">
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  id={"selectAll"}
                  type={"checkbox"}
                />

                <tbody>
                  <TableInformation info={commentsData} />
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row total-tb">
                <div className="d-flex">
                  <p className="mb-0 total-reg">Registros Seleccionados: </p>
                  <span className="ms-1 total-records">
                    {checkedCheckboxes.length}
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row total-tb pe-2">
                <p className="mb-0 total-reg"> Total Registros: </p>
                <span className="ms-1 total-records">
                  {downloadExcel.length}
                </span>
              </div>
            </div>

            <div className="btn-pagination d-flex align-items-center justify-content-between">
              <button
                disabled={checkedCheckboxes.length < 1 ? true : ""}
                className={`btn btn-a ${
                  checkedCheckboxes.length >= 1 ? "btn-asignar" : "btn-disabled"
                }`}
                onClick={() => {
                  asignar();
                }}
              >
                Asignar MDT
              </button>

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={ITEMS_PER_PAGE}
                totalItemsCount={totalItems}
                disabledClass={"disabled-page"}
                pageRangeDisplayed={5}
                onChange={(page) => setCurrentPage(page)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        )}
      </div>

      <ModalAsignar
        open={modalAC}
        asignar={"MDT"}
        close={() => OpenAC()}
        qty={checkedCheckboxes}
      />
    </div>
  );
};

export default ReasignacionMDT;

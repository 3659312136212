export async function updZonaAirtek(arr) {
  var infoArray = arr;

  infoArray.records.forEach((obj) => {
    if (!obj.Ac || obj.Ac === "") {
      obj.Ac = "ZONA AIRTEK";
    }
  });

  return infoArray;
}

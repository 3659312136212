import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Login.css";
import logodark from "../../assets/img/logo-dark.png";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import Button from "../../components/shared/Button/Button";
import emailicon from "../../assets/svg/email-icon.svg";
import passwordicon from "../../assets/svg/password-icon.svg";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

export default function Login() {
  const cookies = new Cookies();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [ls, setLs] = useState(false);
  const [err, setErr] = useState();
  const [hide, setHide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verPass, setverPass] = useState(false);

  useEffect(() => {
    localStorage.setItem("isLogedIn", ls);

    if (hide === false) {
      setTimeout(() => {
        setHide(true);
      }, 2000);
    }
  }, [ls, hide]);

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    var result = {
      UserName: `${data.email}`,
      Password: `${data.password}`,
      TipoConsulta: data.email === `${process.env.EMAIL}` ? "0" : "2",
    };

    axios
      .post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_TOKEN}`,
        result
      )
      .then(function (response) {
        if ("data" in response) {
          var decoded = jwt_decode(response.data);

          var resp = {
            email: decoded.v1,
            pref: decoded.v2,
            rol: decoded.v6,
          };

          if (resp.rol === "004" || resp.rol === "001" || resp.rol === "000") {
            // store response.data

            cookies.set("tkn", response.data, {  secure: true });

            setLs(true);
            localStorage.setItem(
              "username",
              Buffer.from(resp.email).toString("base64")
            );

            localStorage.setItem(
              "rol",
              Buffer.from(resp.rol).toString("base64")
            );

            localStorage.setItem("isLogedIn", ls);
            navigate("/dashboard", { replace: true });

            
          } else {
            throw new Error({
              error:
                "No tiene los permisos necesarios para acceder a esta aplicación.",
            });
          }
        } else {
          throw new Error("Bad response from server");
        }
      })
      .catch(function (error) {
        setHide(false);
        setIsLoading(false);
        setErr(
          "Se produjo un error al iniciar sesión. Vuelve a intentarlo más tarde."
        );
      
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-block bg-left">
          <div className="d-md-flex align-items-end h-100 p-5 text-center">
            <div className="logoarea pt-5 pb-5">
              <p className="left-text">
                Crecemos junto a
                <br />
                nuestros Aliados
                <br />
                Comerciales
              </p>
              <p className="mb-0 l-txt">
                Bienvenidos al portal
                <span className="fw-bold"> Comercial.</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 login ">
          <div className="d-md-flex align-items-center  text-center justify-content-center cont-lg">
            <div></div>
            <div className="login-dv mx-auto align-items-center text-center">
              <img src={logodark} className="logo-dark" alt="logo airtek" />

              <div className="text-center">
                <div className="input-group mb-3">
                  <span
                    className={`input-group-text-rec ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="basic-addon1"
                  >
                    <img src={emailicon} alt="ícono correo" />
                  </span>
                  <input
                    className={`form-control-rec ps-0 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="email"
                    onKeyPress={handleKeypress}
                    placeholder="Correo electrónico"
                    {...register("email", {
                      required: "El campo correo electrónico es obligatorio *",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message:
                          "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve).",
                      },
                    })}
                  />
                </div>

                <div className="input-group">
                  <span
                    className={`input-group-text-rec ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="basic-addon1"
                  >
                    <img src={passwordicon} alt="ícono contraseña" />
                  </span>
                  <input
                    className={`form-control-rec border-end-0 ps-0 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    type={verPass ? "text" : "password"}
                    onKeyPress={handleKeypress}
                    placeholder="Contraseña"
                    {...register("password", {
                      required: "El campo contraseña es obligatorio *",
                    })}
                  />

                  <span
                    className={`input-group-text-lg  ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="basic-addon1"
                  >
                    <i
                      className={
                        verPass
                          ? "fa-solid fa-eye eye-pwd"
                          : "fa-solid fa-eye-slash eye-pwd"
                      }
                      onClick={() => setverPass(!verPass)}
                    ></i>
                  </span>
                </div>

                <ErrorMessage
                  className="lg-error"
                  errors={errors}
                  name="password"
                  as="p"
                />

                <ErrorMessage
                  errors={errors}
                  name="email"
                  className="lg-error"
                  as="p"
                >
                  {({ messages }) => {
                    return (
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="lg-error" key={type}>
                          {message}
                        </p>
                      ))
                    );
                  }}
                </ErrorMessage>

                {err !== undefined && hide === false && (
                  <div className="lg-is-error">
                    <p className="lg-error">{err}</p>
                  </div>
                )}

                <Button
                  text={"Iniciar Sesión"}
                  myClass={"btn btn-dark rounded-pill w-100 btn-login"}
                  funct={handleSubmit(onSubmit)}
                  loading={isLoading}
                  loadingMsg={"Iniciando Sesión"}
                />

                {/* <div className="pt-4">
                  <Link to="/recovery" className="o-pwd">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div> */}
              </div>
            </div>
          </div>

          <div className="footer-a">
            <div className="text-center align-items-center">
              <a href="https://airtek.com.ve/" className="mb-0 website">
                www.airtek.com.ve
              </a>
              <p className="mb-2">
                Síguenos en nuestras
                <span className="sm"> Redes Sociales</span>
              </p>
              <div className=" mx-auto">
                <a href="https://www.instagram.com/airtekinternet/">
                  <i className="icon-sm fab fa-instagram me-3"></i>
                </a>
                <a href="https://www.facebook.com/airtekesinternet/">
                  <i className="icon-sm fab fa-facebook me-3"></i>
                </a>
                <a href="https://www.youtube.com/c/AirtekesInternet">
                  <i className="icon-sm fab fa-youtube me-3"></i>
                </a>
                <a href="https://twitter.com/airtekinternet">
                  <i className="icon-sm fab fa-twitter me-3"></i>
                </a>
                <a href="https://t.me/airtekinternet">
                  <i className="icon-sm fab fa-telegram-plane"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
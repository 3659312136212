// añadir elemento al objeto, inidcando el status del aliado
// 1 -- habilitado
// 0 -- bloqueado

export async function getAliadoStatus(arr, n) {
  arr.forEach((object) => {
    object.estatus = n;
  });
  return arr;
}

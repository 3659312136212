import React from "react";

const TextArea = ({
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  maximLength,
  minimLength,
}) => {
  return (
    <div className="field-name d-flex flex-column">
      <textarea
        className="form-control-ac border-secondary border-end"
        style={{
          resize: "none",
        }}
        placeholder={placeHolder}
        {...register(fieldName, {
          required: {
            value: isRequired,
            message: `El campo ${fieldName} es requerido *.`,
          },
          maxLength: {
            value: maximLength,
            message: `Este campo debe tener como máximo ${maximLength} caracteres.`,
          },
          minLength: {
            value: minimLength,
            message: `Este campo debe tener como mínimo ${minimLength} caracteres.`,
          },
        })}
      ></textarea>

      <p className="lg-error ps-3">
        {
          //If error exists in the textField
          errors[fieldName] && errors[fieldName].message
        }
      </p>
    </div>
  );
};

export default TextArea;
